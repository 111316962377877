<template>
  <div
    class="ya-share2 text-center"
    data-services="vkontakte,odnoklassniki,twitter"
    :data-lang="$options.lang"
    :data-url="link"
  >
  </div>
</template>

<script>
const lang = window.__LANGUAGE__ || 'ru';

export default {
  name: 'YandexShare',
  props: {
    link: String,
  },
  methods: {
    addScript() {
      const el = document.getElementById('yastatic');
      if (el) {
        document.body.removeChild(el);
      }
      const script = document.createElement('script');
      script.setAttribute('src', 'https://yastatic.net/share2/share.js');
      script.setAttribute('async', 'async');
      script.setAttribute('id', 'yastatic');
      document.body.appendChild(script);
    },
  },
  mounted() {
    this.addScript();
  },
  lang,
};
</script>
