<template>
  <Modal
    :title="title"
    :subtitle="subtitle"
    :img="img"
    :name="name"
  >
    <div class="block-text block-text_primary">
      <div class="block__items">
        <div class="block__item">
          <Input
            :input-model="shareLink"
            icon-right="copy"
            readonly
            style="cursor: pointer;"
            v-clipboard="() => shareLink"
            v-clipboard:success="() => $root.showAlert('participant-url-copied', {
              text: $t('general.link_copied'),
              transition: 'fade-down',
              noIcon: true,
              actionText: null,
              autoClose: true,
            })"
          ></Input>
        </div>
        <div class="block__item">
          <div class="heading-line text-center">
            <span
              class="heading-line__text"
              v-html="$t('share_modal.or')"
            ></span>
          </div>
        </div>
        <div class="block__item">
          <YandexShare
            :link="shareLink"
          ></YandexShare>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal/Modal.vue';
import YandexShare from '@/components/YandexShare/YandexShare.vue';
import Input from '@rr-component-library/input/src/main';

export default {
  name: 'ShareModal',
  props: {
    title: String,
    subtitle: String,
    img: String,
    name: String,
  },
  components: {
    Modal,
    YandexShare,
    Input,
  },
  computed: {
    shareLink() {
      return window.location.href;
    },
  },
};
</script>
