<template>
  <div class="progress">
    <slot name="top"></slot>
    <div class="progress__el">
      <div class="progress__bar" v-if="usePoints">
        <div class="progress__points">
          <slot name="points"></slot>
        </div>
      </div>
      <div class="progress__bar" v-else>
        <div class="progress__line"></div>
        <div
          class="progress__empty"
          :style="{ width: width }"
        ></div>
        <div class="progress__separators">
          <span
            v-for="i in total + 1"
            :key="i"
          ></span>
        </div>
      </div>
    </div>
    <div class="progress__info">
      <div class="progress__info-item progress__info-item_main">
        <slot name="caption"></slot>
      </div>
      <div class="progress__info-item">
        <div class="text-nowrap">{{ value }} {{ separator }} {{ total }}</div>
      </div>
    </div>
    <slot name="bottom"></slot>
  </div>
</template>

<script>
export default {
  name: 'Progress',
  props: {
    value: Number,
    total: Number,
    separator: {
      type: String,
      default() {
        return this.$t('general.of');
      },
    },
    usePoints: Boolean,
  },
  computed: {
    width() {
      return `${((this.total - this.value) / this.total) * 100}%`;
    },
  },
};
</script>

<style lang="scss">
@import "./Progress";
</style>
